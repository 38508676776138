var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exercise-master"},[_c('div',{staticClass:"exercise-master__filters space-b-6 filter"},[_c('Select',{staticClass:"filter__col-4",attrs:{"options":_vm.exerciseStatuses,"placeholder":"Выберите статус","dense":"","outlined":"","rounded":"","label":"Статус проверки","clearable":""},on:{"input":function (value) { return _vm.handleExerciseStatus(value); }},model:{value:(_vm.exerciseStatus),callback:function ($$v) {_vm.exerciseStatus=$$v},expression:"exerciseStatus"}})],1),_c('VDataTable',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.filterMasters,"footer-props":_vm.footerOptions,"options":_vm.filter,"sort-by":'onCheckAt',"hide-default-footer":""},on:{"update:options":function($event){_vm.filter=$event},"click:row":_vm.handleRowClick,"update:items-per-page":function($event){_vm.filter.page = 1}},scopedSlots:_vm._u([{key:"header.onCheckAt",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))]),_c('VIcon',{staticClass:"v-data-table-header__icon",attrs:{"color":"teal darken-4"}},[_vm._v("$arrowUp")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.id))])])]}},{key:"item.lostHeart",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[(item.lostHeart)?_c('VIcon',{staticClass:"task-master__heart",attrs:{"color":item.status.value === _vm.isStatusComplete ? 'grey' : 'red'}},[_vm._v("$heartBreak")]):_vm._e()],1)]}},{key:"item.master",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[_c('Tag',{attrs:{"color":_vm.statusColor(item.status.value),"type":_vm.masters.findIndex(function (_item) { return item.id === _item.id; }) === _vm.hoveredRowIndex ? 'fill' : 'bright'}},[_vm._v(_vm._s(_vm.replaceStatus(item.status.value)))])],1)]}},{key:"item.onCheckAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[(item.onCheckAt)?_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: item.onCheckAt, localTZ: _vm.isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }),expression:"{ date: item.onCheckAt, localTZ: isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }"}]}):_c('span')])]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[_c('MentorRateTaskRow',{directives:[{name:"show",rawName:"v-show",value:(item.status.value === _vm.isStatusChecked || item.status.value === _vm.isStatusComplete && !_vm.exercise.autoCheck),expression:"item.status.value === isStatusChecked || item.status.value === isStatusComplete && !exercise.autoCheck"}],attrs:{"value":item.points,"isShowComment":false}})],1)]}},{key:"item.newMessageCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hover-container",on:{"mouseover":function($event){return _vm.handleHoverOver(item.id)},"mouseleave":function($event){_vm.hoveredRowIndex = -1},"touchstart":function($event){return _vm.handleTouch(item.id)}}},[(item.newMessagesCount && _vm.masters.findIndex(function (_item) { return item.id === _item.id; }) !== _vm.hoveredRowIndex)?_c('div',{staticClass:"exercise-master__icon"},[_c('VIcon',{attrs:{"left":"","color":"teal"}},[_vm._v("$comment")]),_vm._v(_vm._s(item.newMessagesCount))],1):_vm._e(),(_vm.masters.findIndex(function (_item) { return item.id === _item.id; }) === _vm.hoveredRowIndex)?_c('ButtonTextIcon',{attrs:{"color":"secondary","icon":"$signOut","tag":"a","to":{ name: 'manager.education.exercises.item.task.master', params: { exerciseUUID: _vm.$route.params.exerciseUUID, groupID: _vm.$route.params.groupID, masterID: item.id, taskUUID: _vm.$route.params.taskUUID }, }}},[_vm._v("Проверить")]):_vm._e()],1)]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }