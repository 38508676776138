






























































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import MentorRateTaskRow from '@/components/views/exercise/mentor/MentorRateTaskRow.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// types
import { ExerciseMasterResource, ExerciseStatus, ExerciseStatusTypeTable } from '@/store/types'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import MentorEducationModule from '@/store/modules/mentor/education'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
// utils
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    MentorRateTaskRow,
    Select,
    TableFooter,
    Tag,
  },
})
export default class ExerciseMasterTable extends Mixins(NotifyMixin, MasterGroupMixin) {
  @Prop({ required: true })
  private masters!: ExerciseMasterResource[]

  private get filterMasters() {
    if (this.exerciseStatus) {
      if (this.exerciseStatus === 'has_messages') {
        return this.masters.filter(master => master.newMessagesCount)
      }
      return this.masters.filter(master => {
        /**
         * Note: Особый случай посколько, на сколько я могу судить
         * ExerciseStatus.CHECKED и ExerciseStatus.ON_CHECK
         * Эквивалентны для фильтрации
         */
        if (this.exerciseStatus === ExerciseStatus.ON_CHECK) {
          return (
            master.status.value === ExerciseStatus.ON_CHECK ||
            master.status.value === ExerciseStatus.CHECKED
          )
        }

        return master.status.value === this.exerciseStatus
      })
    }
    return this.masters
  }

  private get exercise () {
    return MentorExercisesModule.exercise
  }

  private get exerciseStatus() {
    return MentorExercisesModule.exerciseStatusFilter
  }

  private set exerciseStatus(value: ExerciseStatusTypeTable) {
    MentorExercisesModule.setExerciseStatusFilter(value)
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get filter() {
    return MentorExercisesModule.mastersFilter
  }

  private set filter(value: any) {
    MentorExercisesModule.setMastersFilter(value)
  }

  private taskUUID = this.$route.params.taskUUID

  private mounted() {
    this.fetchMasters()
  }

  @Bind
  @Debounce(300)
  private fetchMasters() {
    MentorExercisesModule.fetchMasters({
      masterGroupID: this.currentMasterGroupID,
      taskUUID: this.taskUUID,
    })
      // Если запрос на получение мастеров упал, то запрашивать домашку мастера не имеет смысла, т.к. у наставника нет доступа к этой группе мастеров
      // Очищаем текущую группу мастеров, выводим ошибку запроса и запрашиваем доступные группы мастеров для наставника заново
      .catch((error: any) => {
        MentorEducationModule.setCurrentMasterGroupID(-1)
        this.notifyError(error)
        this.$router.replace({ name: 'manager.education.exercises' })
          .then(() => {
            this.$bus.$emit('fetchMasterGroups')
          })
      })
  }

  private footerOptions = tableFooterOptions

  private isStatusChecked = ExerciseStatus.CHECKED
  private isStatusComplete = ExerciseStatus.COMPLETE

  private hoveredRowIndex = -1

  private get exerciseStatuses () {
    return [
      ...DictionaryModule.exerciseStatusesFilter,
      { name: 'Есть сообщения', value: 'has_messages' },
    ]
  }

  private get headers () {
    return [
      { cellClass: 'cell-width-8', sortable: false, text: '#', value: 'id' },
      { cellClass: 'cell-width-5', sortable: false, text: '', value: 'lostHeart' },
      { sortable: false, text: 'Мастер', value: 'master' },
      { sortable: false, text: 'Статус', value: 'status' },
      { text: 'Дата сдачи', value: 'onCheckAt' },
      { sortable: false, text: 'Оценка', value: 'points' },
      { cellClass: 'cell-width-20', sortable: false, text: 'Сообщения', value: 'newMessageCount' },
    ]
  }

  private statusColor(value: ExerciseStatus): string {
    if (value === ExerciseStatus.WAIT) return 'grey'
    if (value === ExerciseStatus.COMPLETE) return 'teal'
    return 'orange'
  }

  private handleHoverOver(id: number) {
    this.hoveredRowIndex = this.masters.findIndex(_item => _item.id === id)
  }

  private handleTouch(id: number) {
    this.hoveredRowIndex = -1
    this.hoveredRowIndex = this.masters.findIndex(_item => _item.id === id)
  }

  private handleRowClick(master: ExerciseMasterResource) {
    this.$router.push({
      name: 'manager.education.exercises.item.task.master',
      params: {
        exerciseUUID: this.$route.params.exerciseUUID,
        groupID: this.$route.params.groupID,
        masterID: master.id.toString(),
        taskUUID: this.$route.params.taskUUID,
      },
    })
      .catch(() => {return})
  }

  private replaceStatus(value: ExerciseStatus) {
    switch (value) {
    case ExerciseStatus.CHECKED:
      return 'К проверке'
    case ExerciseStatus.COMPLETE:
      return 'Завершено'
    case ExerciseStatus.ON_CHECK:
      return 'К проверке'
    case ExerciseStatus.WAIT:
      return 'Не сдано'
    }
  }

  private handleExerciseStatus(value: ExerciseStatusTypeTable) {
    this.exerciseStatus = value
    this.filter = {
      ...this.filter,
      page: 1,
    }
  }
}
